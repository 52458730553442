// Pug設定
import './modules/pug';

// 外部ライブラリ等の読み込み
import jQuery from 'jquery';
const $ = jQuery;

// モジュール
import { ResponsiveSetting } from './modules/ResponsiveSetting';
import { SmoothScroll } from './modules/SmoothScroll';


/* iPhoneSEへの対応 */
const BREAK_POINT = 374;
ResponsiveSetting(BREAK_POINT);


/* スムーススクロール適応 */
const $href = $('.js_scroll');
SmoothScroll($href);


/* モバイルメニュー */
const mbMenu = $('.js_mbMenu');
const mbMenuTarget = $('.js_mbMenuTarget');

mbMenu.on('click', function() {
  $(this).toggleClass('is_active');
  mbMenuTarget.toggleClass('is_active');
  $('body').toggleClass('is_active');
});


/* カレント制御 */
const currentDir = $('body').data('dir');
const navCurrent = $('.js_current');
navCurrent.each(function(index, el) {
  if($(el).data('current') === currentDir) {
    $(el).addClass('is_active');
  }
});


/* Gnavドロップダウンメニュー */
const gnavDropdownItem = $('.js_gnavDropItem');
const gnavDropDownTarget = $('.js_gnavDropTarget');
const setGanvDropdown = (item, target) => {
  target.hide();
  item.on({
    'mouseenter': function() {
      $(this).find('.js_gnavDropTarget:not(:animated)').slideDown();
    },
    'mouseleave': function() {
      $(this).find('.js_gnavDropTarget').slideUp('slow');
    }
  });
};

$(window).on('load resize', function() {
  const width = $(this).width();
  const breakPoint = 1024;
  if(width > breakPoint) {
    setGanvDropdown(gnavDropdownItem, gnavDropDownTarget);
  } else {
    gnavDropdownItem.off();
  }
});


/* 特定のページのみ、ページ移動時コンテンツまでスクロール */
$(function() {
  if($('body').hasClass('js_scrollToContent')) {
    const target = $('.js_scrollTarget');
    $('html, body').animate({
      scrollTop: target.offset().top - 180
    });
  }
});


/* sticky nav */
// navのcssを変更したいのでposition: stickyを使わない
$(function() {
  const main = $('.js_stickyNavTarget');
  const navPosition = $('.js_stickyNav').offset().top;
  const navHeight = $('.js_stickyNav').outerHeight();
  const breakPoint = 1024;

  $(window).on('scroll', function() {
    const scrollPosition = $(window).scrollTop();
    const windowWidth = $(window).width();
    if(windowWidth > breakPoint) {
      if(navPosition <= scrollPosition) {
        $('.js_stickyNav').addClass('is_sticky');
        main.css('margin-top', navHeight);
      } else if(navPosition > scrollPosition) {
        $('.js_stickyNav').removeClass('is_sticky');
        main.css('margin-top', '0');
      }
    }
  });
});


/* accordion */
$('.js_accordion').on('click', function() {
  const parent = $(this).closest('.bl_acList_item');
  parent.toggleClass('is_active');
  parent.find('.bl_acList_data').slideToggle();
});